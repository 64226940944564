<template>
  <div class="richtext-container">
    <el-form ref="form" :rules="rules" :model="form" label-width="160px">
      <el-form-item :label="`图片（670*300）`" prop="cover_url">
        <upload-one
          width="335px"
          style="height: 150px"
          v-model="form.cover_url"
        ></upload-one>
      </el-form-item>
      <el-form-item label="排序" prop="weight">
        <el-input-number
          v-model="form.weight"
          :min="1"
          :max="9999"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="跳转" prop="link_type">
        <el-select v-model="form.link_type" placeholder="请选择">
          <el-option label="不跳转详情" :value="0"> </el-option>
          <el-option label="跳转详情" :value="1"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="比赛ID" prop="link_to" v-if="form.link_type == 1">
        <el-input v-model="form.link_to"></el-input>
         <!-- <el-select v-model="form.link_to" placeholder="请选择">
          <el-option label="不跳转详情" value="0"> </el-option>
          <el-option label="跳转详情" value="1"> </el-option>
        </el-select> -->
        <!-- <Tinymce
          :height="300"
          v-model="form.description"
          :value="form.description"
        /> -->
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">
          确定
        </el-button>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer"></div>
  </div>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import request from "@/utils/request";

export default {
  components: { Tinymce },
  data() {
    return {
      btnLoading: false,
      form: {
        id: "",
        cover_url: "",
        weight: 1,
        link_type: 0,
        link_to: "",
        description: "",
        category_id: 2,
      },
      rules: {
        cover_url: [
          { required: true, message: "图片不能为空", trigger: "change" },
        ],
        weight: [
          { required: true, message: "权重不能为空", trigger: "change" },
        ],
        link_to: [
          { required: true, message: "跳转链接不能为空", trigger: "change" },
        ],
        link_type: [
          { required: true, message: "跳转类型不能为空", trigger: "change" },
        ],
        description: [
          { required: true, message: "链接不能为空", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.form.id = this.$route.query.id;
    if (this.form.id) {
        this.getDetails();
    }
  },
  methods: {
    saveData() {
      if (this.form.link_type && !this.form.link_to) {
        this.$message({
          type: "error",
          message: "跳转链接不能为空",
        });
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/banners/store",
            method: "post",
            data: this.form,
          })
            .then((res) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
            //   this.getList();
              this.$router.go(-1);
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    getDetails() {
      request({
        url: "/api/schoolend/banners/detail",
        method: "get",
        params: { id: this.form.id },
      }).then((res) => {
        if (res.data) {
          this.form = res.data;
        }
      });
    },
    // handleSave() {
    //   this.$confirm("确定要提交, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       let data = {};
    //       data[this.form.key] = {
    //         value: this.form.value,
    //       };
    //       request({
    //         url: "/api/schoolend/settings/store",
    //         method: "post",
    //         data: data,
    //       }).then(() => {
    //         this.$message({
    //           type: "success",
    //           message: "保存成功!",
    //         });
    //         this.getSetting();
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消",
    //       });
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
.richtext-container {
  width: 90%;
  margin-top: 10px;
}
.box {
  position: relative;
  border: 1px solid #d7d7d7;
  padding: 10px;
  .el-icon-error {
    position: absolute;
    top: -15px;
    right: -15px;
    color: #505050;
    font-size: 30px;
  }
}
.item {
  display: flex;
  margin-bottom: 10px;
  .item-title {
    width: 100px;
    // flex: ;
  }
  .el-input {
    margin-left: 17px;
  }
}
</style>

